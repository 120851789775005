import { inject, Injectable } from "@angular/core";
import { ApiProduction, ApiShow, ShowsApi } from "@tytapp/api";
import { ClientPersistenceService } from '@tytapp/app/client-persistence.service';
import { ApiStateTransfer } from '@tytapp/app/middleware/api-state-transfer';
import { Cache, PagedArray } from "@tytapp/common";

@Injectable()
export class ShowsService {
    private showsApi = inject(ShowsApi);
    private persistence = inject(ClientPersistenceService);
    private apiStateTransfer = inject(ApiStateTransfer);

    cache: Cache<ApiShow> = Cache.shared<ApiShow>('shows', { timeToLive: 1000 * 60 * 15, maxItems: 100 });
    showListCache: Cache<PagedArray<ApiShow>> = Cache.shared<PagedArray<ApiShow>>('shows-list', { timeToLive: 1000 * 60 * 15, maxItems: 1 });
    showFeedCache: Cache<PagedArray<ApiProduction>> = Cache.shared<PagedArray<ApiProduction>>('show-feeds', { timeToLive: 1000 * 60 * 15, maxItems: 1 });

    async all() {
        let shows = await this.persistence.fetchWhenOnline('shows', () => {
            return this.showListCache.fetch('default', async () => await this.showsApi.all().toPromise());
        });
        this.apiStateTransfer.prepopulate(`/content/shows`, {}, shows);
        return shows;
    }

    async get(id: string) {
        return await this.cache.fetch(id, async () => {
            try {
                return await this.showsApi.get(id).toPromise() as ApiShow;
            } catch (e) {
                if (e.json)
                    e = e.json();

                if (e.error === 'not-found')
                    return null;

                throw e;
            }
        });
    }

    async getPodcasts(showId: string, offset?: number, limit?: number) {
        return await this.showFeedCache.fetch(`podcasts-${showId}-page-${offset}-${limit}`, async skip => {
            return await this.showsApi.getShowPodcasts(showId, offset, limit).toPromise();
        });
    }

    async getProductions(showId: string, offset?: number, limit?: number) {
        return await this.showFeedCache.fetch(`productions-${showId}-page-${offset}-${limit}`, async skip => {
            return await this.showsApi.getShowProductions(showId, offset, limit).toPromise();
        });
    }

    async getClips(showId: string, offset?: number, limit?: number) {
        return await this.showFeedCache.fetch(`clips-${showId}-page-${offset}-${limit}`, async skip => {
            return this.showsApi.getShowClips(showId, offset, limit).toPromise();
        });
    }

    async getEpisodes(showId: string, offset?: number, limit?: number) {
        return await this.showFeedCache.fetch(`episodes-${showId}-page-${offset}-${limit}`, async skip => {
            return this.showsApi.getShowEpisodes(showId, offset, limit).toPromise();
        });
    }

    async getArticles(showId: string, offset?: number, limit?: number) {
        return this.showsApi.getShowArticles(showId, offset, limit).toPromise();
    }
}