import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { ShowsService } from './shows.service';
import { SlugService } from '@tytapp/slugs';
import { ApiShow } from '@tytapp/api';

@NgModule({

})
export class ShowsModule {
    private shows = inject(ShowsService);

    constructor() {
        SlugService.registerType<ApiShow>({
            type: 'CMS::Show',
            friendlyName: 'Show',
            getUrl: slug => `/shows/${slug.slug}`,
            getContent: async slug => await this.shows.get(slug.slug),
            getMetadataForContent: async content => ({
                title: content.name,
                url: `/shows/${content.slug ?? content.id}`
            })
        });
    }

    static forRoot(): ModuleWithProviders<ShowsModule> {
        return {
            ngModule: ShowsModule,
            providers: [
                ShowsService
            ]
        }
    }
}